import { Globe } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled } from '@mui/material';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import SwMediaCard from '@lib/components/SwMediaCard';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { SwSection } from '@lib/components/SwSection';
import SwTag from '@lib/components/bedrock/badges/SwTag';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { SwSubNav } from '@lib/components/bedrock/navigation/SwSubNav';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import { Standards } from '@app/emission-factors/Standards';
import { boldFontFamily } from '../BaseLayout';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwPagination from '../components/v1/pagination/SwPagination';
import SwBreadcrumb from '../components/v1/website/SwBreadcrumb';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { grey3, grey5 } from '../lib/components/bedrock/SwColors';
import SwTypography from '../lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '../lib/components/layout/SwWebsiteContainer';

const Header = styled('div')`
    padding-block-start: 64px;
    padding-block-end: 64px;
`;

const Title = styled(SwTypography)`
    max-inline-size: 330px;
`;

const EmissionFactorTemplate = ({ data, pageContext }) => {
    const { title, subtitle, subNavigation, sections, hero } = data.page;

    const { t } = useTranslation();
    const { search, hash } = useLocation();

    const emissionFactors = data.emissionFactors.nodes;
    const { currentPage, pageCount } = data.emissionFactors.pageInfo;
    const { basePath } = pageContext;

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.about.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout>
                    <SwBreadcrumb basePage={'Partners'} page={WEBSITE_MENU_ITEM.emissionFactors.text} />
                    <SwContainer>
                        <Header>
                            <SwTypography color={colors.common.black} component={'h1'} variant={'h3'}>
                                {title}
                            </SwTypography>
                            <SwTypography color={colors.text.secondary} component={'p'} variant={'h3'}>
                                {subtitle}
                            </SwTypography>
                        </Header>
                    </SwContainer>
                    <SwSubNav gap={32}>
                        {subNavigation.map(({ text, target, id }) => (
                            <SwSubNav.Item
                                key={id}
                                replace={false}
                                selected={hash === `#${target}`}
                                to={`${search}#${target}`}
                            >
                                {text}
                            </SwSubNav.Item>
                        ))}
                    </SwSubNav>
                    <SwContainer paddingBlock={'0 32px'}>
                        <SwSection target={sections[0].target}>
                            <Box
                                borderRadius={'8px'}
                                maxHeight={425}
                                mt={4}
                                overflow={'hidden'}
                                style={{ aspectRatio: AspectRatios.widescreen }}
                                width={'100%'}
                            >
                                <GatsbyImage
                                    alt={hero.alt}
                                    image={hero.gatsbyImageData}
                                    objectFit={'cover'}
                                    style={{ height: '100%', width: '100%' }}
                                />
                            </Box>
                            <SwGrid columns={{ xs: 1, md: 2 }} gap={32}>
                                <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={32}>
                                    <Title component={'h2'} variant={'h3'}>
                                        {t('emission_factors_page.section_1_title')}
                                    </Title>
                                </SwFlexBox>
                                <SwTypography bold={true} color={grey3} component={'p'} variant={'h3'}>
                                    {t('emission_factors_page.section_1_subtitle')}
                                </SwTypography>
                            </SwGrid>

                            <SwCmsRenderer content={sections[0].children} />
                        </SwSection>
                    </SwContainer>
                    <WebsiteSectionsLayout>
                        <SwSection target={sections[1].target}>
                            <SwSubNav position={'relative'} />
                            <SwContainer>
                                <SwTypography bold={true} color={grey3} component={'h2'} mb={4} variant={'h4'}>
                                    {t('emission_factors_page.section_2_title')}
                                </SwTypography>
                                <SwGrid columns={{ xs: 1, sm: 2, md: 3 }}>
                                    {emissionFactors.map((factor) => (
                                        <SwMediaCard
                                            aspectRatio={AspectRatios.widescreen}
                                            background={'white'}
                                            layout={'content-bottom'}
                                            media={
                                                <GatsbyImage
                                                    alt={factor.company.logo.alt}
                                                    image={factor.company.logo.gatsbyImageData}
                                                    objectFit={'contain'}
                                                    style={{ width: '60%' }}
                                                />
                                            }
                                            mediaContainerStyle={{ display: 'grid', placeItems: 'center' }}
                                        >
                                            <Stack alignItems={'start'} spacing={2}>
                                                <SwTypography variant={'headingStrong'}>
                                                    {factor.company.name}
                                                </SwTypography>
                                                <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                                                    {factor.tags.map((tag) => (
                                                        <SwTag
                                                            color={tag.color}
                                                            key={tag.id}
                                                            size={'small'}
                                                            uppercase={true}
                                                        >
                                                            {tag.label}
                                                        </SwTag>
                                                    ))}
                                                </Stack>
                                                <SwTypography color={colors.text.secondary} variant={'body2'}>
                                                    {factor.bio}
                                                </SwTypography>
                                                {factor.company.website && (
                                                    <SwInternalLink
                                                        href={factor.company.website}
                                                        rel={'nooponer'}
                                                        size={'small'}
                                                        style={{
                                                            marginLeft: -8,
                                                            fontFamily: boldFontFamily,
                                                            color: grey3
                                                        }}
                                                        target={'_blank'}
                                                        variant={'link'}
                                                    >
                                                        <Globe /> {t('website')}
                                                    </SwInternalLink>
                                                )}
                                            </Stack>
                                        </SwMediaCard>
                                    ))}
                                </SwGrid>
                                {pageCount > 1 && (
                                    <>
                                        <SwLine color={grey5} direction={'horizontal'} spacing={2} />
                                        <SwPagination
                                            basePath={basePath}
                                            currentPage={currentPage ?? 1}
                                            pageCount={pageCount}
                                            target={`#${subNavigation[1].target}`}
                                        />
                                    </>
                                )}
                            </SwContainer>
                        </SwSection>

                        <SwContainer>
                            <SwSection target={sections[2].target}>
                                <SwGrid columns={{ xs: 1, md: 2 }} gap={32} style={{ paddingBlock: 32 }}>
                                    <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={32}>
                                        <Title component={'h2'} variant={'h3'}>
                                            {t('emission_factors_page.section_3_title')}
                                        </Title>
                                    </SwFlexBox>
                                    <SwTypography bold={true} color={grey3} component={'p'} variant={'h3'}>
                                        {t('emission_factors_page.section_3_subtitle')}
                                    </SwTypography>
                                </SwGrid>
                                <SwCmsRenderer content={sections[2].children} />
                                <SwTypography color={grey3} component={'h3'} variant={'h4'}>
                                    {t('emission_factors_page.section_3_title_2')}
                                </SwTypography>
                                <Standards />
                            </SwSection>
                        </SwContainer>

                        <SwSection target={sections[3].target}>
                            <SwContainer>
                                <SwGrid columns={{ xs: 1, md: 2 }} gap={32} style={{ paddingBlock: 32 }}>
                                    <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={32}>
                                        <Title component={'h2'} variant={'h3'}>
                                            {t('emission_factors_page.section_4_title')}
                                        </Title>
                                    </SwFlexBox>
                                    <SwTypography bold={true} color={grey3} component={'p'} variant={'h3'}>
                                        {t('emission_factors_page.section_4_subtitle')}
                                    </SwTypography>
                                </SwGrid>
                            </SwContainer>

                            <SwContainer hasOverflowingContent={true}>
                                <SwLatestContent
                                    content={data.latestUpdates.nodes}
                                    renderItems={(article) => (
                                        <SwRecordCard
                                            baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                            key={article.id}
                                            record={article}
                                            titleHeading={'h3'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                            <SwContainer>
                                <SwCmsRenderer content={sections[3].children} />
                            </SwContainer>
                        </SwSection>
                        <SwContainer>
                            <SwCmsRenderer content={sections[4].children} />
                        </SwContainer>
                    </WebsiteSectionsLayout>
                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getEmissionFactorsPageInformation($locale: GraphCMS_Locale, $limit: Int, $skip: Int) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestUpdates: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                tags: { elemMatch: { key: { eq: "carbon" } } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 3
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        emissionFactors: allGraphCmsEmissionFactor(
            limit: $limit
            skip: $skip
            filter: { locale: { eq: $locale } }
            sort: { order: ASC, fields: rank }
        ) {
            nodes {
                id
                company {
                    ...Essentials_GraphCMS_Company
                    website
                }
                tags {
                    id
                    label
                    color
                }
                bio
            }
            pageInfo {
                currentPage
                pageCount
                perPage
                totalCount
            }
        }
        emissionFactorsByType: allGraphCmsEmissionFactor(filter: { locale: { eq: $locale } }) {
            totalCount
            group(field: tags___label, limit: 9) {
                nodes {
                    id
                }
                fieldValue
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        enPage: graphCmsPage(slug: { eq: "emission-factors" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(slug: { eq: "emission-factors" }, locale: { eq: $locale }) {
            id
            remoteId
            slug
            subtitle
            title
            seo {
                ...Essentials_GraphCMS_Seo
            }
            hero {
                alt
                gatsbyImageData(aspectRatio: 1.778, layout: FULL_WIDTH, placeholder: BLURRED)
            }
            subNavigation {
                ...Essentials_GraphCMS_NavTab
            }
            sections {
                ... on GraphCMS_Section {
                    id
                    __typename
                    target
                    children {
                        ...Essentials_GraphCMS_Divider
                        ...Essentials_GraphCMS_EmphasizedText
                        ...Essentials_GraphCMS_MediaCard
                        ... on GraphCMS_Grid {
                            ...Essentials_GraphCMS_Grid
                            children {
                                ... on GraphCMS_GridItem {
                                    ...Essentials_GraphCMS_GridItem
                                    children {
                                        ...Essentials_GraphCMS_CallToAction
                                        ...Essentials_GraphCMS_FeatureCard
                                        ...Essentials_GraphCMS_AccordionSectionsList
                                        ...Essentials_GraphCMS_LogoCard
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default EmissionFactorTemplate;
